import { useState } from 'react'
import Image from "next/image"
import dynamic from 'next/dynamic'
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import Link from 'next/link'
import cyclist from '../../public/assets/images/cyclist.jpg'
import forest from '../../public/assets/images/forest.jpg'
import woman from '../../public/assets/images/woman.jpg'

const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((fa) => fa.FontAwesomeIcon)
)

const ModalVideo = dynamic(() => import('react-modal-video'))

export default function Focus() {
  const [open, setOpen] = useState(false)
  return (
    <section className="focus py-4 py-lg-5">
      <div className="container px0 pb-5">
        <h2 className="title">
          <small>Benefits</small> Improve aerodynamics to reach your goals
        </h2>
        <div className="mt-5 text-center">
          <button
            className="btn btn-primary video-modal btn-lg"
            onClick={() => setOpen(!open)}
          >
            <FontAwesomeIcon icon={faPlay} /> Why Aerodynamics Matter
          </button>
          <ModalVideo
            isOpen={open}
            start="2"
            videoId="8dI7znDJ1so"
            onClose={() => setOpen(!open)}
          />
        </div>
        <div className="clearfix">
          <div className="card my-5 has-icon float-start">
            <div className="row g-0">
              <div className="col-auto me-5 d-none d-md-block">
                <Image
                  src={cyclist}
                  width="237"
                  height="238"
                  className="border-radius-start-top"
                  alt="Increase performance"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </div>
              <div className="col">
                <div className="card-body">
                  <div className="icon performance" />
                  <h3 className="card-title">Increase performance</h3>
                  <p className="card-text">
                    To make athletes go faster and drones fly further,
                    optimizing aerodynamics is key.
                  </p>
                  <p className="m-1">With AirShaper you can:</p>
                  <ul className="pt-0">
                    <li className="pb-2">Increase top speed</li>
                    <li className="pb-2">Optimize lift & drag</li>
                    <li className="pb-2">
                      Gain the defining last bit of performance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-5 has-icon float-end">
            <div className="row g-0">
              <div className="col-auto me-5 d-none d-md-block">
                <Image
                  src={forest}
                  width="237"
                  height="239"
                  className="border-radius-start-top"
                  alt="Reduce emissions"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </div>
              <div className="col">
                <div className="card-body">
                  <div className="icon emissions" />
                  <h3 className="card-title">Reduce emissions</h3>
                  <p className="card-text">
                    With ever strict regulations, keeping emissions and energy
                    consumption under control is a prime concern for
                    transportation.
                  </p>
                  <p className="m-1">With AirShaper you can:</p>
                  <ul className="pt-0">
                    <li className="pb-2">
                      Find & tackle sources of aerodynamic drag
                    </li>
                    <li className="pb-2">Save fuel & extend range</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card has-icon float-start">
            <div className="row g-0">
              <div className="col-auto me-5 d-none d-md-block">
                <Image
                  src={woman}
                  width="237"
                  height="239"
                  className="border-radius-start-top"
                  alt="Improve comfort"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              </div>
              <div className="col">
                <div className="card-body">
                  <div className="icon comfort" />
                  <h3 className="card-title">Improve comfort</h3>
                  <p className="card-text">
                    From an alley between buildings to the deck of a luxury
                    yacht, air flow impacts wind comfort & wind safety.
                  </p>
                  <p className="m-1">With AirShaper you can:</p>
                  <ul className="pt-0">
                    <li className="pb-2">
                      Detect & eliminate high speed wind zones before a design
                      is even built.
                    </li>
                    <li className="pb-2">
                      Reduce wind noise for vehicles, planes and buildings
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <Link passHref href="/videos" className="btn btn-lg btn-primary">
            Learn Aero Theory <FontAwesomeIcon icon={falAngleRight} />
          </Link>
        </div>
      </div>
    </section>
  );
}
